import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_tabs = _resolveComponent("tm-tabs")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_tabs, {
      modelValue: _ctx.tabValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tabValue) = $event)),
      tabs: _ctx.tabs,
      class: "mb-4",
      "use-router": ""
    }, null, 8, ["modelValue", "tabs"]),
    _createVNode(_component_router_view)
  ]))
}