
import { defineComponent, ref } from 'vue'
import TmTabs from '@/components/shared/TmTabs.vue'

export default defineComponent({
  name: 'Fields',
  components: { TmTabs },
  setup() {
    const tabValue = ref()
    const tabs = ref([
      {
        name: 'input',
        label: 'Input',
        to: { name: 'ui.fields.input' },
      },
      {
        name: 'textarea',
        label: 'Textarea',
        to: { name: 'ui.fields.textarea' },
      },
      {
        name: 'select',
        label: 'Select',
        to: { name: 'ui.fields.select' },
      },
      {
        name: 'checkbox',
        label: 'Checkbox',
        to: { name: 'ui.fields.checkbox' },
      },
      {
        name: 'search',
        label: 'Search',
        to: { name: 'ui.fields.search' },
      },
      {
        name: 'number',
        label: 'Number',
        to: { name: 'ui.fields.number' },
      },
      {
        name: 'datepicker',
        label: 'Datepicker',
        to: { name: 'ui.fields.datepicker' },
      },
      {
        name: 'colorpicker',
        label: 'Colorpicker',
        to: { name: 'ui.fields.colorpicker' },
      },
      {
        name: 'tags',
        label: 'Tags-like',
        to: { name: 'ui.fields.tags' },
      },
    ])

    return {
      tabValue,
      tabs,
    }
  },
})
